import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[6]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[4]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[7]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[100px] pb-[10px] md:pb-[50px] md:pt-[200px] ">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center ">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center ">{rpdata?.dbValues?.[0].description}</p>
            <div className="flex justify-center w-full">
              <ButtonContent />
            </div>
          </div>
          <div className="w-auto h-auto flex justify-center items-center">
            <img
              src={"https://firebasestorage.googleapis.com/v0/b/video1-f8800.appspot.com/o/sastifaction.png?alt=media&token=bedfa19a-0eaa-44d2-b824-86387650f3ab"}
              alt='satisfaction'
              loading='lazy'
              className='w-[200px] h-[300px] md:w-[350px] md:h-[400px] object-contain'
            />
          </div> 
        </div>
      </div>
    </>
  );
}

export default HeroSection;
